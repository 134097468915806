import { Fragment } from 'react';
import './dashboard.css';
import TransactionTable from './_layouts/transaction.table';

const DashboardRender = props => {
  
  let {totalClient,transactions,totalNewTransaction} = {totalClient : 0, transactions : [],totalNewTransaction :  0};

  if(props.renderFrameConfigs.fetchData){
    totalClient = props.renderFrameConfigs.fetchData.totalClient;
    totalNewTransaction = props.renderFrameConfigs.fetchData.totalClient;
    transactions = props.renderFrameConfigs.fetchData.transactions;
  }


 


  if(transactions){
    totalNewTransaction = transactions.filter(transaction => transaction.state === 0).length;
  }

  
  return <Fragment>
            <div className="row">
              <div className="col-xl-4 col-sm-6 col-12"> 
                <div className="card">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="align-self-center">
                          <i className="icon-pencil primary font-large-2 float-left"></i>
                        </div>
                        <div className="media-body text-right">
                          <h3>{totalClient ? totalClient?.toLocaleString('fr-FR') : 0}</h3>
                          <span>CLIENTS</span >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="align-self-center">
                          <i className="icon-speech warning font-large-2 float-left"></i>
                        </div>
                        <div className="media-body text-right">
                          <h3>{transactions ? transactions?.length?.toLocaleString('fr-FR') : 0}</h3>
                          <span>TRANSACTIONS</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="align-self-center">
                          <i className="icon-graph success font-large-2 float-left"></i>
                        </div>
                        <div className="media-body text-right">
                          <h3>{totalNewTransaction ? totalNewTransaction?.toLocaleString('fr-FR') : 0}</h3>
                          <span>Nouvelle transaction</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row p-2'>
              <div className='title text-white p-4'>HISTORIQUE DES TRANSACTIONS</div>

                <div className="card-body my-2">
                  <TransactionTable transactions={transactions.reverse()}/>
                  
                </div>
            </div>
        </Fragment>;
};

export default DashboardRender;
