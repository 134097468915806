import React from "react";
import "./HeroSection.css";
import { NavLink } from "react-router-dom";
import ConvertForm from "./ConvertForm";




const HeroSection = (props) => {
  return (
    <div className="row p-3 p-md-5 mb-4 rounded  my-md-2 my-lg-2 my-xl-2 hero">
      <div className="col-lg-7 px-2 d-flex flex-column justify-content-lg-center hero-left">
        <h1 className="display-4 fst-italic">
          Envoyez de l'argent à <span className="text-strange">l'étranger!</span>
        </h1>
        <p className="lead my-3">
            Effectuez des transferts d'argent entre Haïti et la Russie. Avec
            “well transfer” facilitez vos transactions financières
            internationales en toute fiabilité et sécurité.
        </p>
      </div>
      <div className="col-lg-5 hero-right">
          <ConvertForm {...props}/>           
          <NavLink className="get_started" to="/new-transaction">
            COMMENCEZ MAINTENANT
          </NavLink>      
      </div>
   </div>
  );
};

export default HeroSection;
