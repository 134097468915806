import DefaultController from './default.controller';

export default class DashboardController extends DefaultController {

  async readAllWithSpecification (specifications, path = null) {
    const response = await this.readFirebase (path || this.path);
    // const formatData = this.completeFormat(dataFormatter.ListFormatterResponse (response?.data));
    return {
      ...response,
    };
  }

  async dashboardData(){
    const clients = await this.readAll("clients");
    let totalClient = clients?.data?.length;
    
    
    
    let transactions = await this.readAll("transactions");
   
    

    return {
      success : true,
      message : null,
      data : {
        totalClient,
        transactions : transactions.data,
        transactionCount: transactions?.data?.length
      }
    }
  }
  
}
