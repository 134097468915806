import { useState } from "react";
import { InputFile } from "../../../../../vendor/builders/Form/Input";

export default function ValidationForm(props) {
  console.log(props);

  const [transferProof, setTransfertProof] = useState(null);
  const [transferProofError, setTransferProofError] = useState(null);

  const changeText = (e, value = null, name = null) => {
    e.preventDefault();
    setTransfertProof(value || e.target.value);
  };

  const submitForm = () => {
    if (transferProof) {
      setTransferProofError(false);

      props.data.data.transactionValidation({
        id: props.data.data.id,
        transferProof: transferProof,
      });
    } else {
      setTransferProofError(true);
    }
  };

  return (
    <div className="col-12">
      <div className="text-center text-white bg-primary p-3 mb-3">
        <h5 className="fw-bold ">Validation transaction</h5>
      </div>

      <span className="fw-bold">Preuve de transfert</span>

      <div className="d-flex justify-content-center">
        <InputFile
          fieldInformation={{
            name: "transferProof",
            id: "transferProof",
            disable: false,
          }}
          changeValue={(e, value, name) => {
            changeText(e, value, name);
          }}
          value={transferProof}
        />
      </div>
      {transferProofError ? (
        <span className="text-danger">La preuve de transfert est requise</span>
      ) : null}
      <div className="col-12 d-flex justify-content-end">
        <button onClick={submitForm} className="btn btn-block btn-primary">
          Enregistrer
        </button>
      </div>
    </div>
  );
}
