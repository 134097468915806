import './style.css';
import { useRef} from 'react';
import profile from '../../../../../assets/images/profile.jpg';
import mainTheme from '../../../../../configs/mainTheme';

const SingleBeneficiaryRender = props => {
  const beneficiaryInformation = props.data.rows;

  const componentRef = useRef ();


  return (
    <div className="client-card-information">
        <div ref={componentRef} className="mt-2 p-2">
            <div className="client-content">
                <div className="p-2 text-center text-white client-title">
                    <h4>FICHE BENEFICIAIRE #{beneficiaryInformation?.id}</h4>
                </div>
                <div className="card-body p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={profile} alt="logo" className="rounded" width="100" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">{beneficiaryInformation?.name}</h5>
                            <small>Client </small>
                            <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                <div className="d-flex flex-column">

                                    <span className="articles">N° de téléphone</span>
                                    <span className="number1">
                                    {beneficiaryInformation?.tel} 
                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                 
                </div>
            </div>
            <div className="d-flex justify-content-center image-text border-top">
              <span className="image">
                <img src={mainTheme.appLogo} alt="logo" className="logo" />
              </span>

              <div className="text logo-text">
                <span className="name">FICHE CLIENT WELL TRANSFER</span>
              </div>
            </div>
        </div>
    </div>
  );
};

export default SingleBeneficiaryRender;
