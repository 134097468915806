import React from "react";
import { NavLink } from "react-router-dom";
import TransactionsService from "../../../../../external-communication/services/transactions.service";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SingleTransaction from "./single";


export default function Transactions(props){
    return <TransactionsRender {...props}/>
}


class TransactionsRender extends React.Component {
    service = new TransactionsService();
    
    constructor(props){
        super(props);
        this.state = {
            transactions : [],
            detailTransaction : null,
            openDetailTransactionDialog: false,
            openCancelTransactionDialog: false,
            transactionId: false,
        }
        this.loadTransaction = this.loadTransaction.bind(this);
        this.openDetailDialog = this.openDetailDialog.bind(this);
        this.closeDetailDialog = this.closeDetailDialog.bind(this);
        this.openCancelDialog = this.openCancelDialog.bind(this);
        this.closeCancelDialog = this.closeCancelDialog.bind(this);
        this.confirmCancel = this.confirmCancel.bind(this);
    }

    async componentDidMount(){
        await this.loadTransaction();
    }

    async loadTransaction(){
        if(this.props?.profileInformation?.id){
            const clientTransactionQuery = await  new TransactionsService().transactionByClient(this.props?.profileInformation?.id);
            if(clientTransactionQuery.success){
               await this.setState({
                transactions : clientTransactionQuery.data
               })
            }
        }
    }

    async openDetailDialog(singleData){
        const detailQuery = await this.service.one(singleData.id);
        if(detailQuery.success){
            this.setState({
                openDetailTransactionDialog : true,
                detailTransaction: detailQuery.data
            })
        }

    }

    async closeDetailDialog(){
        this.setState({
            openDetailTransactionDialog : false,
            detailTransaction: null
        });
    }

    async closeCancelDialog(){
        this.setState({
            openCancelTransactionDialog : false,
            transactionId: null
        })
    }

    async openCancelDialog(transactionId){
        this.setState({
            openCancelTransactionDialog : true,
            transactionId: transactionId
        })
    }

    async confirmCancel(){
        const queryDelete = await this.service.cancel(this.state.transactionId);
        if(queryDelete.success){
            this.closeCancelDialog();
            await this.loadTransaction();
        }
    }

    render(){
        return <div className="card">
                    <div className="card-header">
                        <div className="row d-flex justify-content-between">
                            <div className='col-12 col-md-9 col-xl-9 col-lg-9'>
                                Transaction(s)
                            </div>
                            <div className='col-12 col-md-3 col-xl-3 col-lg-3 d-flex justify-content-end'>
                                <NavLink  to="/new-transaction" className="btn btn-sm btn-primary" type="button">
                                    Nouvelle transaction
                                </NavLink>
                            </div>
                        </div>
                        
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th className="border-gray-200" scope="col">Transaction N.</th>
                                        <th className="border-gray-200" scope="col">Date</th>
                                        <th className="border-gray-200" scope="col">Moyen de paiement</th>
                                        <th className="border-gray-200" scope="col">Montant</th>
                                        <th className="border-gray-200" scope="col">Statut</th>
                                        <th className="border-gray-200" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.transactions.length > 0 ?
                                        this.state.transactions.reverse().map(
                                            transaction => {
                                                const total = transaction?.total || 0;
                                                
                                                return <tr key={transaction.id}>
                                                            <td>{transaction.transactionNumber}</td>
                                                            <td>{moment(transaction.dateTransaction).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                            <td>{transaction.paymentWay.name}</td>
                                                            <td>{transaction?.taxe.currency1?.name?.toUpperCase()+" "+total?.toLocaleString('fr-FR')}</td>
                                                            <td>
                                                                {
                                                                    transaction.state === 0 ? 
                                                                        <span className="badge bg-light text-dark">En attente</span> : null

                                                                }
                                                                {
                                                                    transaction.state === 1 ? 
                                                                        <span className="badge bg-success text-white">Validee</span> : null

                                                                }
                                                                {
                                                                    transaction.state === 2 ? 
                                                                        <span className="badge bg-danger text-white">Annulee</span> : null

                                                                }
                                                            </td>     
                                                            <td>
                                                                <div className="d-flex">
                                                                <button onClick={(e) => {
                                                                    this.openDetailDialog(transaction);
                                                                }} title='detail' className="btn btn-sm btn-primary"> 
                                                                    <i>
                                                                        <FontAwesomeIcon icon="eye"/>
                                                                    </i>
                                                                </button>
                                                                {
                                                                    transaction?.state === 0 ? 
                                                                    <button onClick={() => { this.openCancelDialog(transaction.id)}} title='annulé' className="mx-1 btn btn-sm btn-danger"> 
                                                                        <i>
                                                                            <FontAwesomeIcon icon="times"/>
                                                                        </i>
                                                                    </button> : null
                                                                }
                                                                </div>
                                                               
                                                                    
                                                            </td>
                                                        </tr>
                                            }
                                        )
                                        : 
                                        <tr>
                                            <td className="text-center" colSpan={6}>
                                                <span className="text-center">Aucune transaction effectuee :) </span>
                                            </td>
                                        </tr>
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <DialogSingle 
                        open={this.state.openDetailTransactionDialog}
                        transaction={this.state.detailTransaction} 
                        handleClose={this.closeDetailDialog}
                    />
                    <CancelDialog
                         open={this.state.openCancelTransactionDialog}
                         handleClose={this.closeCancelDialog}
                         confirmCancel={this.confirmCancel}
                    />
                </div>
    }
}


const DialogSingle = props =>  { 
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return  <Dialog
                fullScreen={fullScreen}
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="responsive-dialog-title"
            >  
                <div className="px-2 py-2"> 
                    <div className="d-flex justify-content-end px-2">
                        <button className="btn" onClick={props.handleClose}>
                            <FontAwesomeIcon icon="times-circle"/>
                        </button>
                    </div>
                    <SingleTransaction data={props.transaction} />
                </div>
            </Dialog>
}

const CancelDialog = props => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme?.breakpoints?.down('sm'));

    return  <Dialog
                fullScreen={fullScreen}
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Vous confirmer l'annulation?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Souhaitez vous réellement confirmer cette annulation?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={props.handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={props.confirmCancel}>
                        Confirmer
                    </Button>
                </DialogActions>
  </Dialog>
}