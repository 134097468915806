import React from "react";
import "./AboutSection.css";
import AboutImage from "../../../../assets/site/img/AboutUsSideImage.png";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AboutSection = () => {
  return (
    <div className="aboutsection">
      <section className="py-1 py-md-1 px-4">
        <div className="main">
          <h2 className="mb-3 text-center">À propos de nous</h2>
          <p className="lead fs-4 text-center text-secondary mb-3">
            Rapide, securisee, Abordable.
          </p>
          <div className="row row-inverse gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
            <div className="col-12 col-lg-6 col-xl-5">
              <img className="img-fluid rounded" loading="lazy" src={AboutImage} alt="About 1"/>
            </div>
            <div className="col-12 col-lg-6 col-xl-7">
              <div className="row justify-content-xl-center">
                <div className="col-12 col-xl-11">
                
                  <p className="mb-5">
                    Well Transfer est un fournisseur de services de paiement dédié à
                    aider les particuliers à envoyer de l'argent à l'étranger en toute
                    simplicité. Nous nous spécialisons dans la facilitation de
                    transactions internationales sécurisées et efficaces, en mettant
                    l'accent sur les besoins spécifiques des étudiants vivant et
                    étudiant en Russie. Avec notre plateforme accueillante, transférer
                    de l'argent depuis Haïti, les Etats-Unis, la République
                    Dominicaine ou l'Europe vers la Russie n'a jamais été aussi rapide
                    et pratique.
                  </p>
                  <div className="d-flex justify-content-start">
                    <NavLink className='btn btn-outline-primary btn-sm' to={"/aboutus"}>
                      Apprendre encore plus <FontAwesomeIcon icon="arrow-right"/>
                    </NavLink>
                  </div>
                
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutSection;
