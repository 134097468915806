import { Autocomplete, TextField } from "@mui/material";
import { Fragment, useState } from "react";
import { InputFile } from "../../../../../../../vendor/builders/Form/Input";

const AccountInfo = (props) => {
  const [accountInfo, setAccountInfo] = useState(props.oldValue || {});
  const globalInfo = props.globalInfo || {};

  const changeText = (e, value = null, name = null) => {
    e.preventDefault();
    setAccountInfo({
      ...accountInfo,
      [name || e.target.name]: value || e.target.value,
    });
    accountInfo[name || e.target.name] = value || e.target.value;
    setInfo(accountInfo);
  };

  const setInfo = (info) => {
    props.setFormInfo({ ...globalInfo, accountInfo: info });
  };

  const recipient = accountInfo?.paymentWay?.originalObject?.recipient || null;

  return (
    <Fragment>
      <div className="my-2">
        <h5>MOYEN DE PAIEMENT</h5>
      </div>
      <div className="amount row">
        <div className="col-12 mb-3">
          <Autocomplete
            options={props.paymentWays || []}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                error={
                  props?.errors?.find((error) => error.name === "paymentWay")
                    ?.error
                }
                helperText={
                  props?.errors?.find((error) => error.name === "paymentWay")
                    ?.error || null
                }
                fullWidth
                {...params}
                label="Moyen de paiement"
              />
            )}
            name="paymentWay"
            onChange={(e, value) => {
              changeText(e, value, "paymentWay");
            }}
            value={accountInfo.paymentWay}
            error={
              props?.errors?.find((error) => error.name === "paymentWay")?.error
            }
            helperText={
              props?.errors?.find((error) => error.name === "paymentWay")
                ?.error || null
            }
          />
        </div>

        {recipient ? (
                              <Fragment>
                                <div className="col-12  my-3">
                                  <span classNamme="fw-bold">INFORMATION DU COMPTE</span>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-4 col-xl-4 col-lg-4">
                                    <div className="d-flex flex-column">
                                      <samll className="fw-bold">Nom </samll>
                                      <span>{recipient?.name}</span>
                                    </div>
                                    <div className="d-flex flex-column my-3">
                                      <samll className="fw-bold">Numero de compte </samll>
                                      <span>{recipient?.accountNumber}</span>
                                    </div>
                                    <div className="d-flex flex-column my-3">
                                      <samll className="fw-bold">Contact </samll>
                                      <span>{recipient?.tel}</span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-4 col-xl-4 col-lg-4">
                                    <div className="d-flex flex-column">
                                      <samll className="fw-bold">Banque </samll>
                                      <span>{recipient?.bank?.name}</span>
                                    </div>
                                    <div className="d-flex flex-column my-2">
                                      <samll className="fw-bold">Monnaie </samll>
                                      <span>{recipient?.currency?.code}</span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-4 col-xl-4 col-lg-4 my-3">
                                    <span>Preuve de paiement </span>
                                    <div>
                                        <InputFile
                                          fieldInformation={{
                                              name: "proof",
                                              id: "proof",
                                              disable: false,
                                          }}
                                          changeValue={(e, value, name) => {
                                              changeText(e, value, name);
                                          }}
                                          value={accountInfo.proof}
                                        />
                                    </div>
                                    {
                                        props?.errors?.find((error) => error.name === "proof")
                                        ?.error ? <span className="text-danger">
                                                        {props?.errors?.find((error) => error.name === "proof")?.error}
                                                    </span> : null
                                    }
                                    
                                  </div>
                                </div>
                                
                              </Fragment>
                            ) : null}
      
      </div>
      <hr />
    </Fragment>
  );
};

export default AccountInfo;
