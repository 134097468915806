import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class BeneficiariesQuery extends DefaultFirebaseQuery {
  constructor () {
    super ({
      name: 'beneficiaries',
      path: 'beneficiaries',
    });
  }

  clientBeneficiaries = async(clientId)   =>  {
    return await this.controller.clientBeneficiaries (clientId);
  }
}
