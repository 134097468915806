import { Box, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import moment from "moment";
import { useState } from "react";

export default function TransactionTable(props){
    
    const data = props.transactions;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    
    return <Box sx={{ width: '100%' }}>     
                <Paper sx={{ width: '100%', overflow:'hidden' }}>
                    <TableContainer sx={{ maxHeight: 450 }}>
                        <Table stickyHeader size={'small'}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">
                                    Date & Heure
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Reference
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Envoyeur
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Receveur
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    TOTAL TRANSACTION
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                            data.length > 0 ? data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
                                transaction => <StyledTableRow key={transaction.id}>
                                                    <StyledTableCell align="center">
                                                        {moment(transaction.dateTransaction).format("DD/MM/YYYY HH:mm:ss")}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {transaction?.transactionNumber}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {transaction?.sender?.name?.toLocaleString('fr-FR')}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {transaction?.receipter?.name?.toLocaleString('fr-FR')}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {transaction.total.toLocaleString('fr-FR') + " "+transaction.taxe.currency1.code + "("+transaction.taxe.currency1.symbol+")"}
                                                    </StyledTableCell>
                                        </StyledTableRow>

                            ) : <tr>
                                        <td className='text-center text-danger' colSpan={4}>Aucune transaction :)</td>
                                </tr>
                            }
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5,10, 25, 100]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />     
                </Paper>  
            </Box>

}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  