import Service from '../../vendor/bases/service';

export default class ClientsService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'clients',
    });
  }

  async getCurrentClientProfile(){
    return await this.treatQuery (this.query.getCurrentClientProfile ());
  }

  async findClientAffiliates (id) {
    return await this.treatQuery (this.query.findClientAffiliates (id));
  }

  async profileStats (id) {
    return await this.treatQuery (this.query.profileStats (id));
  }
}
