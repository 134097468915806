import React, { Fragment, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import mainTheme from "../../../../configs/mainTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default class ConvertForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            taxes : [],
            currencies : [],
            totalFee : 0,
            sendingAmount : 0,
            receivingAmount : 0,
            rate : 0,
            currenciesParams : null,
            sendCurrency : null,
            receivingCurrency : null,
            currentTaxe : null,
            currentFee : null,
            fees : []
        }

        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.setCurrency = this.setCurrency.bind(this);
        this.initFormParams = this.initFormParams.bind(this);
        this.setTaxeParams = this.setTaxeParams.bind(this);
        this.getRateAndFees = this.getRateAndFees.bind(this);
        this.getSecondFee = this.getSecondFee.bind(this);
        this.feeCalculate = this.feeCalculate.bind(this);
        this.makeConvertAndAddFees = this.makeConvertAndAddFees.bind(this);
        this.getTaxeParams = this.getTaxeParams.bind(this);
        this.getFeeParams = this.getFeeParams.bind(this);
    }

    componentDidMount(){
       this.initFormParams();
    }

    componentDidUpdate(prevProps,prevState){
        if(this.props !== prevProps){
            this.initFormParams();
        }
    }

    initFormParams(){
        const currencies = this.getOptions("currencies");
        const taxes = this.getOptions("changetaxes");
        const fees = this.getOptions("fees");

        const params = this.setTaxeParams(currencies[0],currencies[1]);
        const currentTaxe = params.taxe;
        const currentFee = params.fee;

        

        this.setState({
            taxes : taxes,
            currencies : currencies,
            sendCurrency : currencies[0],
            receivingCurrency : currencies[1],
            currentTaxe: currentTaxe || taxes[0],
            currentFee : currentFee || fees[0],
            fees : fees
        });
    }

    getOptions(title){
        return this.props.options?.find(opt => opt.title === title)?.options || []
    }


    makeConvertAndAddFees(params){
        let givenAmount = params?.amount || this.state.sendingAmount || 0;  
        let taxeParams = params?.taxeParams || this.state.currentTaxe?.originalObject;
        let feeParams = params?.feeParams || this.state.currentFee?.originalObject;
        

        const fees = this.getRateAndFees(feeParams,givenAmount);


        const receivingAmount = (parseFloat(givenAmount) - fees) * parseFloat(taxeParams?.rate)?.toFixed(2);

        this.setState({
            receivingAmount : (receivingAmount > 0 ? receivingAmount : 0  || 0),
            rate: taxeParams?.rate || "Non defini",
            totalFee : fees || "Indefini"
        }); 
    }

    getRateAndFees(feeParams,givenAmount){
        let totalFees = null;
        if(feeParams){

            let getFee = this.getSecondFee(givenAmount,feeParams.feesList); 
            let firstFee = this.feeCalculate(givenAmount,feeParams.processFee,feeParams.calculWay);
            let secondFee = 0;
        
            if(getFee){
                secondFee = this.feeCalculate(givenAmount,getFee.bankFee,getFee.calculWay);
            }
        
            totalFees = firstFee  +  secondFee;
        }

        return totalFees;
    }

    feeCalculate(amount,feeValue,calculWay){
        let fee = null;
        if(calculWay?.toUpperCase() === "FIXE"){
            fee = parseFloat(feeValue?.replace(",","."));
        }else if(calculWay?.toUpperCase() === "POURCENTAGE"){
            fee = parseFloat(feeValue?.replace(",",".")) * parseFloat(amount) / 100;     
        }
        return fee || 0;
    }

    getSecondFee(amount,feeList){
        return feeList?.find(fee => {
                if(fee.smallAmount && !fee.maxAmount){
                    return fee.smallAmount <= amount;
                }else if(!fee.smallAmount && fee.maxAmount){
                    return fee.maxAmount >= amount;
                }
                return fee.smallAmount <= amount && fee.maxAmount >= amount
        }) 
    }

    setCurrency(name,value){
        this.setState({[name] : value});
        if(name === "sendCurrency"){
            this.setTaxeParams(value,this.state.receivingCurrency)
        }else{
            this.setTaxeParams(this.state.sendCurrency,value)
        }
    }

    setTaxeParams = (sendingCurency,receivingCurrency) => {
        
        const feeParams = this.getFeeParams(sendingCurency,receivingCurrency);
        const taxeParams = this.getTaxeParams(sendingCurency,receivingCurrency);
        
        this.applyTaxeAndAddFees({taxeParams : taxeParams, feeParams : feeParams});

         
        return {
            fee : feeParams,
            taxe : taxeParams
        }
    }

    getTaxeParams = (sendingCurency,receivingCurrency) => {
        return this.getOptions("changetaxes").find(taxe => {
            return taxe.originalObject.currency1.id === sendingCurency?.value && taxe.originalObject.currency2.id === receivingCurrency?.value;
        });
    }
    
    getFeeParams = (sendingCurency,receivingCurrency) => {
        return this.getOptions("fees").find(fee => {
            return fee.originalObject.currency.id === sendingCurency?.value && fee.originalObject.currency2.id === receivingCurrency?.value;
        });
    }

    applyTaxeAndAddFees(params){
        this.setState({
            currentTaxe : params.taxeParams,
            totalFee : 0,
            receivingAmount : 0,
            sendingAmount : 0,
            currentFee : params.feeParams
        });

        this.makeConvertAndAddFees({taxeParams : params?.taxeParams?.originalObject,feeParams : params?.feeParams?.originalObject});
    }

    handleAmountChange = async (e) => {
        const value = e.target.value;
        await this.setState({sendingAmount : value})
        this.makeConvertAndAddFees({amount : value})
    }

    render(){
        return  <Fragment>
                    <div className="amount row p-2">
                        <div className="col-12 border border-1 border-black amount-input p-md-3 my-2">
                            <div className="amount-input-title fw-bold fst-italic">
                                Montant d'envoi
                            </div>
                            <div className="amount-input-line row my-1">
                 
                                <div className="col-md-7 col-lg-7 col-xl-7 col-5">
                                    <TextField onChange={this.handleAmountChange} fullWidth label="Montant envoyé" />
                                </div>
                                
                                <div className="col-md-5 col-lg-5 col-xl-5 col-7">
                                    <CurrencySelectAutoComplete 
                                        name = "sendCurrency"
                                        selectCurrency = {this.state.sendCurrency}
                                        currencies={this.state.currencies}
                                        onChange={this.setCurrency} 
                                    />
                                </div>
                            </div>
                        </div> 
                        <div className="col-12 border border-1 border-black amount-input p-md-3 my-2">
                            <div className="amount-input-title fw-bold fst-italic">
                                Montant reçu
                            </div>
                            <div className="amount-input-line row my-1 p-1">
                                <p className="text-start border border-2 py-2 col-md-7 col-lg-7 col-xl-7 col-5 text-primary fw-bold ">
                                    {this.state.receivingAmount ? this.state.receivingAmount?.toFixed(2) : 0}
                                </p>
                                
                                <div className="col-md-5 col-lg-5 col-xl-5 col-7">
                                    <CurrencySelectAutoComplete 
                                        name = "receivingCurrency"
                                        selectCurrency = {this.state.receivingCurrency}
                                        currencies={this.state.currencies}
                                        onChange={this.setCurrency} 
                                    />
                                </div>
                            </div>        
                        </div>
                        <hr />
                        <div className="fees row">
                            <div className="col-6">
                                <p>Frais d'envoi</p>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <p>
                                    {this.state.currentTaxe?.originalObject?.currency1?.symbol || "₽"}
                                    {this.state.totalFee}
                                </p>
                            </div>
                        
                        </div>
                        <hr />
                        {
                            this.state.currentTaxe ? 
                            <div className="fees row">
                                <div className="col-6">
                                    <p>Taux de change actuel</p>
                                </div>
                                <div className="col-6 d-flex justify-content-end">
                                    <p>{this.state.currentTaxe?.originalObject?.currency1?.symbol || "₽"}1 = {this.state.currentTaxe?.originalObject?.currency2?.symbol || "$"}{this.state.rate}</p>
                                </div> 
                            </div> : null  
                        }
                    </div>
                </Fragment>
    }
}


const CurrencySelectAutoComplete = (props) => {

    const [openDropdown,setOpenDropdown] = useState(false);

    const currency = props.selectCurrency || null;

    const onHandleDropdownVisible = () => {
        setOpenDropdown(!openDropdown);
    }
    
    const handleChangeCurrency = (currency) => {
        props.onChange(props.name,currency)
    }

    return  <Fragment>
               {
                !openDropdown ?  <p onClick={onHandleDropdownVisible} style={{cursor: "pointer"}}  className="text-start border border-2 py-2 px-1 col-12  mr-2 d-flex justify-content-between cursor-pointer">
                                    <span  className="fw-bold my-auto mx-2">
                                        {currency?.label || "Devise"}
                                    </span>   
                                
                                    <span  className="fw-bold my-auto">
                                        <img
                                            loading="lazy"
                                            width="40"
                                            height="40"
                                            className="img img-thumbnail mx-2"
                                            src={`${currency?.imgLabelSrc || mainTheme.appLogo}`}
                                            srcSet={`${currency?.imgLabelSrc || mainTheme.appLogo} 2x`}
                                            alt="img"
                                        /> 
                                        <FontAwesomeIcon icon="angle-down"/>
                                    </span>
                                </p> : 
                                <Autocomplete
                                    options={props.currencies}
                                    onChange={(e,value) => {handleChangeCurrency(value)}}
                                    sx={{ width: '100%' }}
                                    value={currency}
                                    onBlur={onHandleDropdownVisible}
                                    renderInput={
                                        (params) => <TextField fullWidth {...params} label="Devise" />
                                    }
                                    renderOption={ (props,option) => { return <AutoCompleteLine key={option.label} option={option} {...props} />}
                                }
                              />

               } 

            </Fragment>
}

const AutoCompleteLine = ({option,...props}) => {
    return <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                    loading="lazy"
                    width="50"
                    height="50"
                    className="img img-thumbnail"
                    srcSet={`${option?.imgLabelSrc || mainTheme.appLogo} 2x`}
                    src={`${option?.imgLabelSrc || mainTheme.appLogo}`}
                    alt="img"
                />
                {option.label}
        </Box>
}