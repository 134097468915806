import React, { Fragment } from "react";
import Transactions from "../transactions";
import { NavLink } from "react-router-dom";
import apiConfig from "../../../../../configs/config";
import ClientsService from "../../../../../external-communication/services/clients.service";



export default function DashboardProfileRender(props){

    let transactions = [];
    
    const profile = props?.profileInformation;
    
    
    const sponsoringCode = profile?.code;


    return <Fragment>
                <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                <DashboardStats profile={profile} code={sponsoringCode}/>
                <Transactions profileInformation={props?.profileInformation} transactions={transactions}/>
             </Fragment>
}


class DashboardStats extends React.Component{
    baseUrl = apiConfig.baseUrl;
    
    constructor(props){
        super(props);
        this.state = {
            profileStats : {}
        }
    }

    
    async componentDidMount(){
        if(this.props.profile){
            const clientQuery = await new ClientsService().profileStats(this.props.profile.id);
            this.setState({
                profileStats: clientQuery.data
            })
        }
    }



    render(){
        return  <div className="row">
                    <div className="col-lg-12 mb-4">
                        <div className="card border-start-lg border-start-primary">
                            <div className="card-body">
                                <div className="small text-muted">Lien d'affiliation</div>
                                <div className="h4 text-info">
                                <NavLink 
                                        to={`${this.baseUrl+"/register/"+this.props.code}`}
                                        role="button" className="btn-link">
                                        {this.baseUrl+"/register/"+this.props.code}

                                </NavLink> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-lg-4 mb-4">
                        <div className="card border-start-lg border-start-primary">
                            <div className="card-body">
                                <div className="small text-muted">Nombre de beneficiaire</div>
                                <div className="h3">{this.state.profileStats?.beneficiariesCount || 0}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="card border-start-lg border-start-secondary">
                            <div className="card-body">
                                <div className="small text-muted">Nombre de transaction</div>
                                <div className="h3">{this.state.profileStats?.transactionsCount || 0}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="card border-start-lg border-start-success">
                            <div className="card-body">
                                <div className="small text-muted">Nombre affiliés</div>
                                <div className="h3 d-flex align-items-center">{this.state.profileStats?.affilaitesCount || 0}</div>
                            </div>
                        </div>
                    </div>
                </div>
    } 
}