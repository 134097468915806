import BaseBackScreen from './../base-back-screen';
import ValidationForm from './_layouts/validation-form';

export default class TransactionsScreen extends BaseBackScreen {
  name = 'Transactions';

  loadOtherDialogContent(actionName,title=null,dataId=null){
    let otherContent = {};
    const config = this.loadFrameContent();
    if(actionName === 'validation'){
      otherContent = {
        actionName : actionName,
        componentName :  title,
        pDialogRender : ValidationForm,
        data : {
          id : dataId,
          transactionValidation : this.props.data.transactionValidation,
        },
        size : config?.dialogsConfig?.deliverDialog?.size || "sm"
      }
    }
    
    return otherContent;
  }
}
