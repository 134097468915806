import { TextField } from "@mui/material";
import { InputTypePhone } from "../../../../../../vendor/builders/Form/Input";
import { useState } from "react";

export default function AddOrEditBeneficiaryForm(props){
    const [formInfo,setFormInfo] = useState(props.credentials);

    const changeText = (e, value = null,name = null) => {
        setFormInfo({
            ...formInfo,
            [name || e.target.name] : value || e.target.value
        })
    }

    return <div className="px-3">
                <div className='my-2'>
                    <h5 className="text-center">ENREGISTREMENT BENEFICIAIRE</h5> 
                </div>
                 <div className="amount row">
                    <div className="col-6  my-3">
                        <TextField
                            fullWidth
                            label="Nom et prenom(s) du beneficiaire"
                            onChange={changeText}
                            name = "name"
                            value={formInfo?.name}
                            error={props?.errors?.find(error => error.name === "name")?.error}
                            helperText={props?.errors?.find(error => error.name === "name")?.error || null}
                        />
                    </div>
                    <div className="col-6  my-2">
                        <InputTypePhone
                            error={props?.errors?.find(error => error.name === "tel")?.error}
                            value={formInfo?.tel}
                            changeValue = {(e,value = null,name = null) => {
                                    changeText(e,value,name)
                                }
                            }
                            fieldInformation = {
                                {
                                label : 'Contact du beneficiaire',
                                name: 'tel'
                                }
                            }
                            />
                    </div>

                    <div className="col-6  my-3">
                        <TextField
                            fullWidth
                            label="Email"
                            onChange={changeText}
                            name = "email"
                            type="email"
                            value={formInfo?.email}
                            error={props?.errors?.find(error => error.name === "email")?.error}
                            helperText={props?.errors?.find(error => error.name === "email")?.error || null}
                        />
                    </div>
                    <div className="col-6  my-3">
                        <TextField
                            fullWidth
                            label="N° de compte"
                            onChange={changeText}
                            name = "accountNumber"
                            value={formInfo?.accountNumber}
                            error={props?.errors?.find(error => error.name === "accountNumber")?.error}
                            helperText={props?.errors?.find(error => error.name === "accountNumber")?.error || null}
                        />
                    </div>
                </div>
                <div className="px-2 d-flex justify-content-end" onClick={() => {props.submitForm(formInfo)}}>
                    <button className="btn btn-primary">
                        ENREGISTRER    
                    </button>        
                </div>
            </div>
}