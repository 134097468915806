import React from "react";
import ClientsService from "../../../../../external-communication/services/clients.service";

export default function Affiliates(props){
    return <AffiliatesRender {...props}/>
}

class AffiliatesRender extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            affiliates : []
        }
        this.loadAffiliates = this.loadAffiliates.bind(this);

    }

    async componentDidMount(){
        await this.loadAffiliates();
    }

    async loadAffiliates(){
        if(this.props?.profileInformation?.id){
            const clientTransactionQuery = await new ClientsService().findClientAffiliates(this.props?.profileInformation?.id);
            if(clientTransactionQuery.success){
               await this.setState({
                affiliates: clientTransactionQuery.data
               })
            }
        }
    }



    render(){
        return <div className="card mb-4">
                <div className="card-header">
                    <div className="row">
                        <div className='col-12 col-md-9 col-xl-9 col-lg-9'>
                            AFFILIE(S)
                        </div>
                    </div>
                    
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th className="border-gray-200" scope="col">Nom & prenoms.</th>
                                    <th className="border-gray-200" scope="col">Contact</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.affiliates.length > 0 ? 
                                    this.state.affiliates.map(
                                        affiliate => {
                                            return   <tr>
                                                        <td>{affiliate.name}</td>
                                                        <td>{affiliate.tel}</td>
                                                    </tr>
                                        }
                                    )
                                   : <tr>
                                    <td className="text-center" colSpan={2}>
                                        Aucun affilie :)
                                    </td>
                                </tr>
                                }
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    }
}

