import { Autocomplete, TextField } from "@mui/material";
import { Fragment, useState } from "react";

export default function PersonalInfo(props){
    const [personalInfo,setPersonalInfo] = useState(props.oldValue || {});
    const globalInfo = props.globalInfo || {};
    
    const changeText = (e, value = null, name = null) => {
        e?.preventDefault();
        setPersonalInfo({
            ...personalInfo,
            [name || e.target.name] : value || e.target.value  
        });
    }

    const setInfo = (info) => {
        props.setFormInfo({...globalInfo,personalInfo : personalInfo});
    }

    return <Fragment>
            <div className='my-2'>
                <h5>INFORMATION SUR CLIENT & BENEFICIAIRE</h5> 
            </div>
            <div className="amount row">
                <div className="col-12  my-3">
                    <Autocomplete
                        options={props.beneficiaries || []}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField error={props?.errors?.find(error => error.name === "receipter")?.error}
                        helperText={props?.errors?.find(error => error.name === "receipter")?.error || null} fullWidth {...params} label="Receveur" />}
                        name = "receipter"
                        onChange={(e,value) => {changeText(e,value,"receipter")}}
                        value={personalInfo.receipter}
                        error={props?.errors?.find(error => error.name === "receipter")?.error}
                        helperText={props?.errors?.find(error => error.name === "receipter")?.error || null}
                        onBlur={(e) =>  setInfo(personalInfo)}
                    />
                </div>

              
            </div>
        </Fragment>

}