import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import AddOrEditBeneficiaryForm from "./form";
import BeneficiariesService from "../../../../../external-communication/services/beneficiaries.service";


export default function Beneficiaries(props){
    return <BeneficiariesRender {...props}/>
}


class BeneficiariesRender extends React.Component {

    service = new BeneficiariesService();    

    constructor(props){
        super(props);
        this.state = {
            beneficiaries : [],
            isFormOpen : false,
            formPayload : null,
            deleteId : null,
            formErrors : []
        }
        this.loadBeneficiaries  = this.loadBeneficiaries.bind(this);
        this.openFormDialog = this.openFormDialog.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.closeFormDialog = this.closeFormDialog.bind(this);
        this.openDeleteDialog = this.openDeleteDialog.bind(this);
        this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
        this.confirmDelete = this.closeDeleteDialog.bind(this);
    }

    async componentDidMount(){
        await this.loadBeneficiaries();
    }

    async loadBeneficiaries(){
        if(this.props?.profileInformation?.id){
            const clientBeneficiariesQuery = await this.service.clientBeneficiaries(this.props?.profileInformation?.id);
            if(clientBeneficiariesQuery.success){
               await this.setState({
                beneficiaries : clientBeneficiariesQuery.data
               })
            }
        }
    }

    async openFormDialog(id = null){
        let payload = null;
        if(id){
            const querySingleBenef = await this.service.one(id);
            
            if(querySingleBenef.success){
                payload = querySingleBenef.data;
            }
        }

        this.setState({
            isFormOpen : true,
            formPayload : payload
        });
    }

    async openDeleteDialog(id = null){
        
        this.setState({
            isDeleteOpen : true,
            deleteId : id
        });
    }

    async closeDeleteDialog(){
        this.setState({
            isDeleteOpen : false,
            deleteId : null
        });
    }

    async closeFormDialog(id = null){
        
        this.setState({
            isFormOpen : false
        });
    }

    async confirmDelete(){
        const queryDelete = await this.service.delete(this.state.deleteId);
        if(queryDelete.success){
            this.closeDeleteDialog();
            await this.loadBeneficiaries();
        }

    }

    async submitForm(payload){
        
        let data = {};

        for(let line in payload){
            data = {
                ...data,
                [line]: {value : payload[line]}
            }
        }

        data.client = {value : this.props?.profileInformation};

        const queryRegsiter = this.state.formPayload ? await this.service.update(data,this.state.formPayload.id) : await this.service.create(data) 
        
        if(queryRegsiter.success){
            this.closeFormDialog();
            await this.loadBeneficiaries();
        }else{
            let errors = [];

            for(let line in queryRegsiter.errors){
                
                errors.push ({
                    name : line,
                    error : queryRegsiter.errors[line].errors
                })
            }

            this.setState({formErrors : errors})
        }
        
    }

    render(){
        return <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className='col-12 col-md-9 col-xl-9 col-lg-9'>
                               BENEFICIAIRES
                            </div>
                            <div className='col-12 col-md-3 col-xl-3 col-lg-3 d-flex justify-content-end'>
                                <button  onClick={() => { this.openFormDialog()}} className="btn btn-sm btn-primary" type="button">
                                    Nouveau
                                </button>
                            </div>
                        </div>
                        
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th className="border-gray-200" scope="col">Nom & Prénom(s)</th>
                                        <th className="border-gray-200" scope="col">N° compte</th>
                                        <th className="border-gray-200" scope="col">Contact</th>
                                        <th className="border-gray-200" scope="col">Email</th>
                                        <th className="border-gray-200" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.beneficiaries.length > 0 ?
                                        this.state.beneficiaries.map(
                                            beneficiary => {
                                                return <tr key={beneficiary.id}>
                                                            <td>{beneficiary.name}</td>
                                                            <td>{beneficiary.accountNumber}</td>
                                                            <td>{beneficiary.tel}</td>
                                                            <td>{beneficiary?.email}</td>
                                                            <td>
                                                                <button title='editer' onClick={() => {this.openFormDialog(beneficiary.id)}} className="btn btn-sm btn-success"> 
                                                                    <i>
                                                                        <FontAwesomeIcon icon="pen"/>
                                                                    </i>
                                                                </button>  
                                                                
                                                            </td>
                                                        </tr>
                                            }
                                        )
                                        : 
                                        <tr>
                                            <td className="text-center" colSpan={4}>
                                                <span className="text-center">Aucun bénéficiaire :) </span>
                                            </td>
                                        </tr>
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <FormDialog errors={this.state.formErrors} submitForm={this.submitForm} handleClose={this.closeFormDialog} formPayload={this.state.formPayload} open={this.state.isFormOpen} />
                    <DeleteDialog confirmDelete={this.confirmDelete}  handleClose={this.closeDeleteDialog} open={this.state.isDeleteOpen}/>                
                </div>
    }
}


const FormDialog = props =>  { 
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme?.breakpoints?.down('sm'));

    return  <Dialog
                fullScreen={fullScreen}
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="responsive-dialog-title"
            >  
                <div className="px-2 py-2"> 
                    <div className="d-flex justify-content-end px-2">
                        <button className="btn" onClick={props.handleClose}>
                            <FontAwesomeIcon icon="times-circle"/>
                        </button>
                    </div>
                    <AddOrEditBeneficiaryForm errors={props.errors} submitForm={props.submitForm} credentials={props.formPayload} />
                </div>
            </Dialog>
}

const DeleteDialog = props => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme?.breakpoints?.down('sm'));

    return  <Dialog
                fullScreen={fullScreen}
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Vous confirmer la suppression?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Souhaitez vous réellement supprimer cette donnée?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={props.handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={props.confirmDelete}>
                        Confirmer
                    </Button>
                </DialogActions>
  </Dialog>
}