import { get, push, ref, remove, set, update } from "firebase/database";
import OperationResponse from "../responses/operation.response";
import db from "../db";
import VALIDATORS from "../utils/validators";

export default class BaseController {
  rules = [];
  errors = [];

  formatToObject = [];

  async executeOperation(operationName, path, credentials = null) {

    switch (operationName) {
      case "insertion":
        return await set(push(ref(db, path)), credentials);
      case "mise à jour":
        delete credentials?.id;
        return await update(ref(db, path), credentials);
      case "suppression":
        return await remove(ref(db, path));
      default:
        return await get(ref(db, path));
    }
  }

  async operation(credentials, operationName, path) {
    if (this.errors.length > 0) {
      return OperationResponse(
        false,
        null,
        this.errors,
        "Echec " + operationName + " de données"
      );
    }

    const execResponse = await this.executeOperation(
      operationName,
      path,
      credentials
    );
    return OperationResponse(
      true,
      execResponse,
      [],
      "Succès " + operationName + " de données"
    );
  }

  completeFormat(data) {
    data.forEach(async (d, index) => {
      data[index] = await this.formatSingle(d);
    });
    return data;
  }

  formatSingle(data) {
    const keys = Object.keys(data);

    this.formatToObject.forEach(async (element) => {
      const name = element.field;
      if (keys.includes(name)) {
        const donnee = await this.makeGetRequest(
          `${element.source + "/" + data[name]}`
        );
        if (donnee.exists()) {
          data[name] = {
            id: donnee.key,
            ...donnee.val(),
          };
        }
      }
    });

    return data;
  }

  createFirebase = async (credentials, path = null) => {
    this.rules = [];
    this.checkRules(credentials, this.rules);
    const backResponse = await this.operation(credentials, "insertion", path);
    return backResponse;
  };

  rulesChecker(errorsBag, credentials, rules, dataId = null) {
    rules.forEach((rule) => {
      let check = this.getSingleRule(rule.title, rule.rules, {
        ...credentials,
        credentialValue: credentials[rule.title],
        rowId: dataId,
      });
      if (check?.errors?.length > 0) {
        errorsBag.push(check);
      }
    });
    return errorsBag;
  }

  getSingleRule(title, rules, otherValue) {
    let ruleChecker = { name: title, errors: [] };
    rules.forEach(async (rule) => {
      let ruleName = rule;
      if (ruleName.includes(":")) {
        const conditions = ruleName.split(":");
        if (conditions.length > 0) {
          ruleName = conditions[0];
          if (conditions[0] === "unique") {
            otherValue = {
              ...otherValue,
              collectionName: conditions[1],
              fieldName: conditions[2],
            };
          } else if (conditions[0] === "min") {
            otherValue = {
              ...otherValue,
              minValue: conditions[1],
            };
          } else if (conditions[0] === "max") {
            otherValue = {
              ...otherValue,
              maxValue: conditions[1],
            };
          } else if (conditions[0] === "minText") {
            otherValue = {
              ...otherValue,
              minTextValue: conditions[1],
            };
          } else if (conditions[0] === "maxText") {
            otherValue = {
              ...otherValue,
              maxTextValue: conditions[1],
            };
          }
        }
      }
      switch (ruleName) {
        case "unique":
          const uniqueRequired = await VALIDATORS.uniqueValidator(
            otherValue.credentialValue,
            otherValue.collectionName,
            otherValue.fieldName,
            otherValue?.rowId
          );
          if (uniqueRequired !== null) {
            ruleChecker.errors.push(uniqueRequired);
          }
          break;
        case "email":
          const msgEmail = VALIDATORS.emailValidator(
            otherValue.credentialValue
          );
          if (msgEmail !== null) {
            ruleChecker.errors.push(msgEmail);
          }
          break;
        case "required":
          const msgRequired = VALIDATORS.requiredValidator(
            otherValue.credentialValue
          );
          if (msgRequired !== null) {
            ruleChecker.errors.push(msgRequired);
          }
          break;
        case "min":
          const minRequired = VALIDATORS.minValidator(
            otherValue.credentialValue,
            otherValue.minValue
          );
          if (minRequired !== null) {
            ruleChecker.errors.push(minRequired);
          }
          break;
        case "minText":
          const minTextRequired = VALIDATORS.minValidator(
            otherValue.credentialValue,
            otherValue.minTextValue
          );
          if (minTextRequired !== null) {
            ruleChecker.errors.push(minTextRequired);
          }
          break;
        case "max":
          const maxRequired = VALIDATORS.maxValidator(
            otherValue.credentialValue,
            otherValue.maxRequired
          );
          if (maxRequired !== null) {
            ruleChecker.errors.push(maxRequired);
          }
          break;
        case "confirm":
          const confirm = VALIDATORS.confirmValidator(
            otherValue.credentialValue,
            otherValue.confirmPassword
          );
          if (confirm !== null) {
            ruleChecker.errors.push(confirm);
          }
          break;
        default:
          break;
      }
    });

    return ruleChecker;
  }

  checkRules(credentials, rules) {
    this.rulesChecker(this.errors, credentials, rules);
  }
  checkUpdateRules(credentials, rules, dataId) {
    this.rulesChecker(this.errors, credentials, rules, dataId);
  }

  async readFirebase(path) {
    return await this.operation(null, "récupération", path);
  }

  updateFirebase = async (credentials, id, path = null) => {
    this.checkUpdateRules(credentials, this.rules, id);
    return await this.operation(credentials, "mise à jour", path);
  };

  deleteFirebase = async (path) => {
    return await this.operation(null, "suppression", path);
  };

  async makeGetRequest(path) {
    return await get(ref(db, path));
  }
}
