import BeneficiariesService from '../../../external-communication/services/beneficiaries.service';
import BaseBackComponent from '../base-back-component';

export default class BeneficiariesComponent extends BaseBackComponent {
  name = 'Beneficiaries';

  options = [
    {
      title: 'clients',
      keys: ['name'],
    },
    {
      title: 'paymentways',
      keys: ['name'],
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
    },
    service: new BeneficiariesService()
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions();
  }

  otherSingleDataInit (data) {
    return data;
  }
}
