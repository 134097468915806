import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import ClientsService from "../../../../../external-communication/services/clients.service";


class ClientAffiliates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      affiliates: [],
    };
    this.loadClientAffiliates = this.loadClientAffiliates.bind(this);
  }

  async componentDidMount() {
    await this.loadClientAffiliates(this.props.clientId);
  }

  async loadClientAffiliates(clientId) {
    const request = await new ClientsService().findClientAffiliates(
      clientId
    );
    if (request.success) {
      this.setState({ affiliates: request.data });
    }
  }

  render() {
    return <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="table-responsive">
                    <TableContainer component={Paper}>
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                        <TableHead sx={{ "& th": {
                            color: "white",
                            backgroundColor: "black"
                            }}}>
                            <TableRow>
                                <TableCell sx={{ fontWeight:"bold" }}>Nom & prenom(s)</TableCell>
                                <TableCell sx={{ fontWeight:"bold" }}>Tel.</TableCell>
                                <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>Email</TableCell>
                                <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>Adresse</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            

                            {
                                this.state.affiliates?.length > 0 ?   this.state.affiliates?.map((row,index) => {
                            
                                    
                                return (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="td" sx={{fontWeight : 'bold'}} scope="articleName">
                                            {row.name}
                                        </TableCell>
                                        <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                            {row?.tel} 
                                        </TableCell>
                                        <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                            {row?.email} 
                                        </TableCell>
                                        
                                        <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                            {row?.address}
                                        </TableCell>

                                    </TableRow>
                                )}) : 
                                    <TableRow>
                                        <TableCell sx={{ textAlign:"center" }} colSpan={5} component="td" scope="row">
                                            Aucun affilié pour ce client :)
                                        </TableCell>
                                    </TableRow>
                            }
                            
                        </TableBody>
                    </Table>
                    </TableContainer>
                </div>
            </div>
  }
}





export default ClientAffiliates;