import paginateData from "../responses/paginate.response";
import dataFormatter from "../responses/row-formatter.response";
import filterData from "../utils/filter.utils";
import BaseController from "./base.controller";

export default class DefaultController extends BaseController {
  constructor(params) {
    super();
    this.path = params.path;
  }

  async create(credentials, path = null) {
    const creds = await this.otherTreatCredentials(credentials);

    const response = await this.createFirebase(creds, path || this.path);

    this.treatmentAfterCreation(credentials);

    return {
      ...response,
      data: response?.success
        ? dataFormatter.SingleFormatterResponse(response.data)
        : null,
    };
  }

  async treatmentAfterCreation(payload) {}

  async otherTreatCredentials(credentials) {
    return credentials;
  }

  async otherUpdateTreatCredentials(credentials) {
    return credentials;
  }

  async readAllWithSpecification(specifications, path = null) {
    const response = await this.readFirebase(path || this.path);

    const formatData = dataFormatter.ListFormatterResponse(response?.data);

    return {
      ...response,
      data: response?.success
        ? paginateData(filterData(formatData, specifications), specifications)
        : null,
    };
  }

  readAll = async (path = null) => {
    const response = await this.readFirebase(path || this.path);
    return {
      ...response,
      data: response?.success
        ? dataFormatter.ListFormatterResponse(response.data)
        : null,
    };
  };

  readOne = async (id, path = null) => {
    const response = await this.readFirebase(
      `${(path || this.path) + "/" + id}`
    );
    return {
      ...response,
      data: response?.success
        ? dataFormatter.SingleFormatterResponse(response.data)
        : null,
    };
  };

  update = async (id, credentials, path = null) => {
    const payload = await this.otherUpdateTreatCredentials(credentials);

    const response = await this.updateFirebase(
      payload,
      id,
      `${(path || this.path) + "/" + id}`
    );

    return {
      ...response,
      data: dataFormatter.SingleFormatterResponse(response.data),
    };
  };

  delete = async (id, path = null) => {
    const response = await this.deleteFirebase(
      `${(path || this.path) + "/" + id}`
    );
    return {
      ...response,
      data: dataFormatter.SingleFormatterResponse(response.data),
    };
  };
}
