import { Fragment } from "react";
import fiche from '../../../../../../assets/images/fiche.jpg';


import "./style.css";
import { capitalizeFirstLetter } from "../../../../../../bootstrap/Helpers/utils";

const SingleTransaction = props => {
    const transactionInformation = props.data;

    const sentCurrency = transactionInformation?.taxe?.currency1;


    return <Fragment>
              <div class="container mt-1 mb-1">
                    <div className="p-2 text-center text-white fee-card-title">
                    <h4>DETAIL TRANSACTION  #{transactionInformation?.transactionNumber}</h4>
                </div>
                <div className="card-content p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={fiche} alt="logo" className="rounded" width="120" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">
                                TRANSACTION ID : {capitalizeFirstLetter(transactionInformation?.id?.toUpperCase() || "")}</h5>
                                <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                <div className="d-flex flex-column">
                                    <span className="articles">Devise envoie</span>
                                    <span className="number1">
                                        {transactionInformation?.taxe?.currency1?.name?.toUpperCase()}
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="articles">Devise de reception</span>
                                    <span className="number1">
                                        {transactionInformation?.taxe?.currency2?.name?.toUpperCase()}
                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-md-12">
                            <div class="bg-pay p-3">
                                <div class="d-flex justify-content-between mt-2"> 
                                    <span class="fw-500">Envoyeur</span> 
                                    <span>{transactionInformation?.sender?.name}</span> 
                                </div>
                                <div class="d-flex justify-content-between mt-2"> 
                                    <span class="fw-500">Destinaire</span> 
                                    <span>{transactionInformation?.receipter?.name}</span> 
                                </div>
                                <hr/>
                                <div class="d-flex justify-content-between mt-2"> <span class="lh-16 fw-500">
                                    Moyen de paiement
                                    </span> <span>
                                        {transactionInformation?.paymentWay?.name}
                                        </span> 
                                </div>
                                <div class="d-flex justify-content-between mt-2"> 
                                    <span class="lh-16 fw-500">Preuve de paiement</span> 
                                    <span>
                                        <a href={transactionInformation?.proof} rel="noreferrer" target="_blank" className="btn btn-sm btn-primary">
                                            Voir la preuve
                                        </a> 
                                    </span> 
                                </div>
                                <hr/>
                                {
                                    transactionInformation?.transferProof ? <Fragment>
                                        <div class="d-flex justify-content-between mt-2"> 
                                            <span class="lh-16 fw-500">Preuve de transfert</span> 
                                            <span>
                                                <a href={transactionInformation?.transferProof} rel="noreferrer" target="_blank" className="btn btn-sm btn-primary">
                                                    Voir la preuve
                                                </a> 
                                            </span> 
                                        </div>
                                        <hr/>
                                    </Fragment> : null
                                }
                                <div class="d-flex justify-content-between mt-2"> 
                                    <span class="fw-500">Frais d'envoi </span> 
                                    <span>{transactionInformation?.fee?.toLocaleString('fr-FR') + " "+
                                            sentCurrency?.name?.toUpperCase() }</span> 
                                </div>
                                <div class="d-flex justify-content-between mt-2"> 
                                    <span class="fw-500">Montant envoye </span> <span>
                                    {parseFloat(transactionInformation?.receivedAmount || 0).toFixed(2)?.toLocaleString('fr-FR') + " "+
                                            sentCurrency?.name?.toUpperCase() }    
                                    </span> 
                                </div>
                                <hr/>
                                <div class="d-flex justify-content-between mt-2"> <span class="fw-500">
                                    Total </span> <span class="text-success">
                                        {
                                            transactionInformation?.total?.toLocaleString('fr-FR') + " "+
                                            sentCurrency?.name?.toUpperCase() }</span> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
}

export default SingleTransaction;