import BaseBackComponent from '../base-back-component';

export default class ClientsComponent extends BaseBackComponent {
  name = 'Clients';

  options = [
    {
      title: 'clients',
      keys: ['name'],
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions();
  }

}
