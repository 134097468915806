import TextField from '@mui/material/TextField';
import React, { Fragment, useEffect, useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import avatarImg from "../../../../assets/images/profile.jpg";
import imgPiker from "../../../../assets/images/img.jpg";
import fileImg from "../../../../assets/images/img.jpg";
import { capitalizeFirstLetter, getBase64 } from '../../../../bootstrap/Helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-phone-input-2/lib/material.css'
import MuiPhoneNumber from "material-ui-phone-number";
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { InputAdornment } from '@mui/material';

export default function InputBuilder(props){
    let inputInformation = props?.fieldInformation;

    let Input = null;

    switch( inputInformation?.type?.toLowerCase()){
        case 'user-avatar' : 
                Input = AvatarPicker;
            break;
       case 'date' : 
            Input = InputTypeDate;
        break;
        case 'datetime' : 
                Input = InputTypeDateTime;
            break;
        case 'date-interval' : 
                Input = DateRangePickerValue;
            break;
        case 'datetime-interval' : 
            Input = DateTimeRangePickerValue;
            break;    
        case 'checkbox' : 
                Input = InputTypeCheckBox;
            break;
        case 'phone' : 
                Input = InputTypePhone;
            break;
        case 'image' : 
                Input = ImagePicker;
            break;
        case 'percentage': 
                Input = PercentageInput
            break;                 
        default : 
                 Input = InputTypeText;
                break;
    }

    return  <BaseInput render={Input} fieldInformation={inputInformation}/>
}

class BaseInput extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            value : null,
            error : null
        }
        this.setValue = this.setValue.bind(this);
        this.setError = this.setError.bind(this);
        this.changeValue = this.changeValue.bind(this);
    }

    async setValue(value){
       await this.setState({value: value});
    }

    async setError(error){
        await this.setState({error: error});
     }

    async componentDidUpdate(prevProps,prevState){
        if(this.props.fieldInformation !== prevProps.fieldInformation){
            await this.setValue(this.props.fieldInformation?.defaultValue?.value);
          
            if(this.props.fieldInformation?.defaultValue?.errors !== prevProps.fieldInformation?.defaultValue?.errors){
                await this.setError(this.props.fieldInformation?.defaultValue?.errors);
            }
        }
    }

    changeValue = async (e,value = null,name = null) => {
       await this.props?.fieldInformation?.handleChange(e,value,name)
    }

    render(){
        let inputInformation = this.props?.fieldInformation;
        const Render = this.props.render;
        return <Render error={this.state.error} value={this.state.value} changeValue={this.changeValue} fieldInformation={inputInformation}/>
    }
}

const NumericFormatCustom = React.forwardRef(
    function NumericFormatCustom(props, ref) {
      const { onChange, ...other } = props;
  
      return (
        <NumericFormat
            {...other}
            valueIsNumericString
            onValueChange={(values) => {
                onChange({
                    target: {
                      name: props.name,
                      value: values.value,
                    },
                });
            }}
            thousandSeparator = " "
        />
      )
    },
);
  
NumericFormatCustom.propTypes = {
name: PropTypes.string.isRequired,
onChange: PropTypes.func.isRequired,
};
  
export function PercentageInput(props){
    let inputInformation = props?.fieldInformation;


        const [localValue, setLocalValue] = useState(null);
        const [valueFromProps, setValueFromProps] = useState(props.value);
        const [fromEditChange, setFromEditChange] = useState(false);

        useEffect(() => {
            setValueFromProps(props.value)
            if(valueFromProps){
                if(!fromEditChange){
                    setLocalValue(valueFromProps);
                }
            }
        },[localValue,props.value,fromEditChange,valueFromProps]);

        const changeInputValue = (e) => {
           // e.preventDefault();
           setLocalValue(e.target.value);
           setFromEditChange(true);
        }

        const callExternalChange = (e) => {
            let value = e.target.value;
            value = e.target.value.replaceAll(" ","")
            props.changeValue(e, value);

            setFromEditChange(true);
        } 
        return  <div className={`form-group col-${inputInformation?.col} my-2`}>
                      <TextField
                        error={props.error && props.error.length > 0}
                        id={inputInformation?.id}
                        label={capitalizeFirstLetter(inputInformation?.label)}
                        name={inputInformation?.name}
                        fullWidth
                        value={localValue || ""}
                        onChange={changeInputValue}
                        helperText={props.error || null}
                        slotProps={{
                            input: {
                              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                            },
                       }}
                       inputProps={
                            { 
                                inputProps: { min: 0, max: 100 },
                                inputComponent: NumericFormatCustom
                            }
                        }
                        disabled={inputInformation?.disable}
                        size={inputInformation?.size || "medium"}
                        onBlur={callExternalChange}
                    /> 
                </div>
}


export function InputTypeText(props) {
        let inputInformation = props?.fieldInformation;

        const [localValue, setLocalValue] = useState(null);
        const [valueFromProps, setValueFromProps] = useState(props.value);
        const [fromEditChange, setFromEditChange] = useState(false);

        useEffect(() => {
            setValueFromProps(props.value)
            if(valueFromProps){
                if(!fromEditChange){
                    setLocalValue(valueFromProps);
                }
            }
        },[localValue,props.value,fromEditChange,valueFromProps]);

        const changeInputValue = (e) => {
           // e.preventDefault();
           setLocalValue(e.target.value);
           setFromEditChange(true);
        }

        const callExternalChange = (e) => {
            let value = e.target.value;

            if(inputInformation?.type === "number"){
                value = e.target.value.replaceAll(" ","")
            }

           
            
            props.changeValue(e, value);

            setFromEditChange(true);
        } 

        return  <div className={`form-group col-${inputInformation?.col} my-2`}>
                    {
                        inputInformation?.type === "number" ?  
                        <TextField
                            error={props.error && props.error.length > 0}
                            id={inputInformation?.id}
                            label={capitalizeFirstLetter(inputInformation?.label)}
                            name={inputInformation?.name}
                            fullWidth
                            value={localValue || ""}
                            onChange={changeInputValue}
                            helperText={props.error || null}
                            InputProps={
                                { 
                                    inputProps: { min: inputInformation?.minValue, max: inputInformation?.maxValue },
                                    inputComponent: NumericFormatCustom
                                }
                            }
                            disabled={inputInformation?.disable}
                            size={inputInformation?.size || "medium"}
                            onBlur={callExternalChange}
                        /> :  <TextField
                                error={props.error && props.error.length > 0}
                                id={inputInformation?.id}
                                label={capitalizeFirstLetter(inputInformation?.label)}
                                name={inputInformation?.name}
                                type={inputInformation?.type}
                                fullWidth
                                value={localValue || ""}
                                onChange={changeInputValue}
                                helperText={props.error || null}
                                InputProps={
                                { 
                                    inputProps: { min: inputInformation?.minValue, max: inputInformation?.maxValue },
                                }
                                
                            }
                                disabled={inputInformation?.disable}
                                size={inputInformation?.size || "medium"}
                                onBlur={callExternalChange}
                            />
                    }

                   
                </div> 
}


export function InputTypeCheckBox(props){
        let inputInformation = props?.fieldInformation;
        const changeValue = async e => {
            await props.changeValue(e,!props.value)
        }
        return <div className={`form-group col-${inputInformation?.col} my-2`}>
                    <FormGroup>
                        <FormControlLabel 
                            id={inputInformation?.id}
                            control={
                                <Checkbox 
                                    name={inputInformation?.name}
                                    checked={props.value || false}
                                    onChange={changeValue}
                                />
                            } 
                            label={capitalizeFirstLetter(inputInformation?.label)}
                        />
                    </FormGroup>
                </div>
}


export function AvatarPicker(props){
        let inputInformation = props?.fieldInformation;
       

        const changeImage = async (e) => {
            const file = e.target.files[0];
            const base64Img = await getBase64(file);
            props.changeValue(e,base64Img,inputInformation?.name);
        } 
    
        const removeImg = e => {
            props.changeValue(e,"null-img",inputInformation?.name);
        }
        return <div className={`form-group col-${inputInformation?.col} my-2`}>
                    <img src={props.value || avatarImg} alt="user_img" className="img img-thumbnail mx-1 mb-1" width='80px' height='80px'/>

                    <div className="d-flex">
                        <label htmlFor={inputInformation?.id} className="mx-1 mb-0 ">
                            <span className="btn btn-sm btn-success">Changer photo</span>
                        </label>
                        {
                            props.value ? <button type="button" onClick={removeImg} className='btn btn-sm btn-danger'>
                            <FontAwesomeIcon icon="trash" />    
                        </button> : null
                        }
                           
                    </div>
                    <input
                        id={inputInformation?.id} 
                        type="file" 
                        className="d-none" 
                        name={inputInformation?.name} 
                        onChange={changeImage}
                        accept="image/png, image/jpeg, image/jpg"
                    />
                </div>
}

export function ImagePicker(props){
    let inputInformation = props?.fieldInformation;
   
    const changeImage = async (e) => {
        const file = e.target.files[0];
        const base64Img = await getBase64(file);
        props.changeValue(e,base64Img,inputInformation?.name);
    } 

    const removeImg = e => {
        props.changeValue(e,"null-img",inputInformation?.name);
    }
    return <div className={`form-group col-${inputInformation?.col} my-2`}>
                <img src={props.value || imgPiker} alt="user_img" className="img img-thumbnail mx-1 mb-1" width='80px' height='80px'/>

                <div className="d-flex">
                    <label htmlFor={inputInformation?.id} className="mx-1 mb-0 ">
                        <span className="btn btn-sm btn-success">Changer photo</span>
                    </label>
                    {
                        props.value ? <button type="button" onClick={removeImg} className='btn btn-sm btn-danger'>
                        <FontAwesomeIcon icon="trash" />    
                    </button> : null
                    }
                       
                </div>
                <input
                    id={inputInformation?.id} 
                    type="file" 
                    className="d-none" 
                    name={inputInformation?.name} 
                    onChange={changeImage}
                    accept="image/png, image/jpeg, image/jpg"
                    disabled={inputInformation?.disable}
                />
            </div>
}


export function InputFile(props){
    let inputInformation = props?.fieldInformation;
   
    const changeFile = async (e) => {
        const file = e.target.files[0];
        const base64Img = await getBase64(file);
        props.changeValue(e,base64Img,inputInformation?.name);
    } 

    const removeImg = e => {
        props.changeValue(e,"null-img",inputInformation?.name);
    }
    return <div className={`form-group col-${inputInformation?.col} my-2`}>
                <img src={fileImg} alt="user_img" className="img img-thumbnail mx-1 mb-1" width='80px' height='80px'/>

                <div className="d-flex">
                    <label htmlFor={inputInformation?.id} className="mx-1 mb-0 ">
                        <span className="btn btn-sm btn-success">Changer fichier</span>
                    </label>
                    {
                        props.value ?<Fragment>
                                        <a rel="noreferrer" target='_blank' className='btn btn-sm btn-primary mx-2' href={props.value}>Consulter</a>
                                        <button type="button" onClick={removeImg} className='btn btn-sm btn-danger'>
                                            <FontAwesomeIcon icon="trash" />    
                                        </button>
                                    </Fragment> : null
                    }
                       
                </div>
                <input
                    id={inputInformation?.id} 
                    type="file" 
                    className="d-none" 
                    name={inputInformation?.name} 
                    onChange={changeFile}
                    disabled={inputInformation?.disable}
                />
            </div>
}

export function InputTypePhone(props){
    let inputInformation = props?.fieldInformation;

    const handleChange = async value => {
        props.changeValue(null,value,inputInformation?.name);
    }

    return <div className={`form-group col-${inputInformation?.col} my-2`}>
                <MuiPhoneNumber
                    error={props.error && props.error.length > 0}
                    id={inputInformation?.id}
                    fullWidth
                    label={capitalizeFirstLetter(inputInformation?.label)}
                    variant="outlined"
                    defaultCountry={"ru"}
                    onChange={handleChange}
                    value={props.value}
                    enableLongNumbers
                    helperText={props.error || null}
                    disabled={inputInformation?.disable}
                />
            </div>
}



let dateObj = {};
export function DateRangePickerValue(props) {
    let inputInformation = props?.fieldInformation;

    const [startDate,setStartDate] = useState(null);
    const [endDate,setEndDate] = useState(null);
  
    const [maxStartDate,setMaxStartDate] = useState(new Date());
  
    const [minEndDate,setMinEndDate] = useState(new Date());
  
  
    const changeDate = async (e, value = null, name = null) => {
      if(e.target.name === "startDate"){
        await setStartDate(value || e.target.value)
        await setMinEndDate(value || e.target.value);
        dateObj.start = value || e.target.value;
      }
      
      if(e.target.name === "endDate"){
        await setEndDate(value || e.target.value);
        await setMaxStartDate(value || e.target.value);
        dateObj.end = value || e.target.value;
  
      }


      props.changeValue(e,buildNewDateInterval(dateObj.start,dateObj.end),inputInformation?.name);
    }
  
    const buildNewDateInterval = (start,end,separator = ",") => {
      let newDate = null;
  
      if(start && end){
        newDate = moment(start).format('YYYY-MM-DD') + separator+ moment(end).format('YYYY-MM-DD');
      }else{
        if(start && !end){
          newDate = moment(start).format('YYYY-MM-DD') + separator+ moment(start).format('YYYY-MM-DD');
        }else if(!start && end) {
          newDate = moment(end).format('YYYY-MM-DD') + separator+ moment(end).format('YYYY-MM-DD');      
        }
      }
      
      return newDate;
    }
  
    return (
     <Fragment>
        <div className={`row form-group col-${inputInformation?.col} my-2`}>
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <InputTypeDate
                    changeValue={changeDate}
                    fieldInformation={
                    {
                        label:"Date debut",
                        id:"startDate",
                        value:startDate || null,
                        size: props.size || "small",
                        changeDate:changeDate,
                        name:"startDate",
                        max:maxStartDate  
                    } 
                    }
                />
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <InputTypeDate
                    changeValue={changeDate}
                    fieldInformation={
                    {
                        label:"Date fin",
                        id:"endDate",
                        value:endDate || null,
                        size: props.size || "small",
                        changeDate:changeDate,
                        name:"endDate",
                        min:minEndDate   
                    } 
                    }
                />
            </div>

            
        </div>
       
     </Fragment>
    );
} 




export function InputTypeDate(props){
    let inputInformation = props?.fieldInformation;

    const [focus, setFocused] = useState(false);
    const [hasValue, setHasValue] = useState(false);

    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);


    const changeDateValue = (e, value = null, name = null) => {
       e.preventDefault(); 
        if(e.target.value){
            setHasValue(true);
        }else{
            setHasValue(false);
        }
        props.changeValue(e,e.target.value,inputInformation?.name);
    }
        return  <div className={`form-group col-${inputInformation?.col} my-2`}> 
                    <TextField
                        error={props.error && props.error.length > 0}
                        helperText={props.error || null}
                        label={capitalizeFirstLetter(inputInformation?.label)}
                        id={inputInformation?.id}
                        value={props?.value}
                        fullWidth
                        onChange={changeDateValue}
                        name={inputInformation?.name}
                        max={inputInformation?.maxValue || null}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        type={hasValue || focus ? "date" : "text"}
                        min={inputInformation?.minValue}
                        disabled={inputInformation?.disable}
                    />
                </div>
      
}

export function DateTimeRangePickerValue(props) {
    let inputInformation = props?.fieldInformation;

    const [startDate,setStartDate] = useState(null);
    const [endDate,setEndDate] = useState(null);
  
    const [maxStartDate,setMaxStartDate] = useState(new Date());
  
    const [minEndDate,setMinEndDate] = useState(new Date());
  
  
    const changeDate = async (e,value = null, name = null) => {
      if(e.target.name === "startDate"){

        await setStartDate(value || e.target.value)
        await setMinEndDate(value || e.target.value);
        dateObj.start = value || e.target.value;
      }
      
      if(e.target.name === "endDate"){
        await setEndDate(value || e.target.value);
        await setMaxStartDate(value || e.target.value);
        dateObj.end = value || e.target.value;
  
      }


      props.changeValue(e,buildNewDateInterval(dateObj.start,dateObj.end),inputInformation?.name);
    }
  
    const buildNewDateInterval = (start,end,separator = ",") => {
      let newDate = null;
  
      if(start && end){
        newDate = moment(start).format('YYYY-MM-DD HH:mm:ss') + separator+ moment(end).format('YYYY-MM-DD HH:mm:ss');
      }else{
        if(start && !end){
          newDate = moment(start).format('YYYY-MM-DD HH:mm:ss') + separator+ moment(start).format('YYYY-MM-DD HH:mm:ss');
        }else if(!start && end) {
          newDate = moment(end).format('YYYY-MM-DD HH:mm:ss') + separator+ moment(end).format('YYYY-MM-DD HH:mm:ss');      
        }
      }
      
      return newDate;
    }
  
    return (
     <Fragment>
        <div className={`row form-group col-${inputInformation?.col} my-2`}>
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <InputTypeDateTime
                    changeValue={changeDate}
                    fieldInformation={
                    {
                        label:"Date debut",
                        id:"startDate",
                        value:startDate || null,
                        size: props.size || "small",
                        changeDate:changeDate,
                        name:"startDate",
                        max:maxStartDate  
                    } 
                    }
                />
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <InputTypeDateTime
                    changeValue={changeDate}
                    fieldInformation={
                    {
                        label:"Date fin",
                        id:"endDate",
                        value:endDate || null,
                        size: props.size || "small",
                        changeDate:changeDate,
                        name:"endDate",
                        min:minEndDate   
                    } 
                    }
                />
            </div>

            
        </div>
       
     </Fragment>
    );
} 

export function InputTypeDateTime(props){
    let inputInformation = props?.fieldInformation;

    const [focus, setFocused] = useState(false);
    const [hasValue, setHasValue] = useState(false);

    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);


    const changeDateValue = e => {
       e.preventDefault(); 
        if(e.target.value){
            setHasValue(true);
        }else{
            setHasValue(false);
        }
        props.changeValue(e,moment(e.target.value).format("YYYY-MM-DD HH:mm:ss") ,inputInformation?.name);
    }
        return  <div className={`form-group col-${inputInformation?.col} my-2`}> 
                    <TextField
                        error={props.error && props.error.length > 0}
                        helperText={props.error || null}
                        label={capitalizeFirstLetter(inputInformation?.label)}
                        id={inputInformation?.id}
                        value={props?.value}
                        fullWidth
                        onChange={changeDateValue}
                        name={inputInformation?.name}
                        max={inputInformation?.maxValue || null}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        type={hasValue || focus ? "datetime-local" : "text"}
                        min={inputInformation?.minValue}
                        disabled={inputInformation?.disable}
                    />
                </div>
      
}