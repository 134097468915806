import React from "react";
import profile from '../../../../../assets/images/profile.jpg';
import "./profile.css";

export default function Account(props){

    const profileInformation = props?.profileInformation;

    return <div className="card-profile">
                <div className="p-2 text-center text-white client-title">
                    <h4>PROFILE CLIENT</h4>
                </div>
                <div className="card-body p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={profile} alt="logo" className="rounded" width="100" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">{profileInformation?.name}</h5>
                            <div className="p-2 mt-2 bg-primary d-flex flex-md-row flex-column justify-content-between rounded text-white stats">
                                <div className="d-flex flex-column">
                                    <span className="articles">N° de téléphone</span>
                                    <span className="number1">
                                    {profileInformation?.tel} 
                                    </span>
                                </div>
                              
                                <div className="d-flex flex-column">
                                    <span className="articles">Email</span>
                                    <span className="number1">
                                        {profileInformation?.email} 
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="articles">Code de sponsoring</span>
                                    <span className="number1">
                                         {profileInformation?.code} 
                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>
            </div>
}