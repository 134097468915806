import Service from "../../vendor/bases/service";

export default class BeneficiariesService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'beneficiaries',
    });
  }

  clientBeneficiaries = async(clientId)   =>  {
    return await this.treatQuery (this.query.clientBeneficiaries (clientId));
  }
}