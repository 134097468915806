import BaseBackComponent from './../base-back-component';

export default class TransactionsComponent extends BaseBackComponent {
  name = 'Transactions';

  options = [
    {
      title: 'changetaxes',
      keys: ['name'],
    },
    
    {
        title: 'currencies',
        keys: ["name",'symbol'],
        imgTarget:"flag"
    },
    {
      title: 'clients',
      keys: ['name'],
    },
    {
      title: 'fees',
      keys: ['name'],
    },
    {
      title: 'beneficiaries',
      keys: ['name','accountNumber'],
    },
    {
      title: 'paymentways',
      keys: ['name'],
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
    },
  };

  constructor (props) {
    super (props);
    this.state = {
      ...this.state,
      transactionValidation : this.transactionValidation
    }
    this.transactionValidation = this.transactionValidation.bind(this);
  }

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions();
  }

  otherSingleDataInit (data) {
    return data;
  }

  async otherHandleFieldTreatment(name,value){

    if(name === "sentAmount"){
      await this.fixFeesAndTotalSend({amount : value})
    }

    if(name === "taxe"){
      await this.fixFeesAndTotalSend({feeParams : value})
    }
  }

  async fixFeesAndTotalSend(params){
    
    let givenAmount = params?.amount || this.state.formPayload.sentAmount?.value || 0;  
    
    let feesParams = params?.feeParams || this.state.formPayload.taxe?.value;
    
    let totalFees = 0;

    let totalReceived = 0;
    
    if(feesParams){
      totalReceived =  parseFloat(givenAmount) * parseFloat(feesParams.rate)

      let getFee =  feesParams.fee.feesList?.find(fee => {
        if(fee.smallAmount && !fee.maxAmount){
          return fee.smallAmount <= givenAmount;
        }else if(!fee.smallAmount && fee.maxAmount){
          return fee.maxAmount >= givenAmount;
        }
        return fee.smallAmount <= givenAmount && fee.maxAmount >= givenAmount
      }) 

      
      let firstFee = 0;
      let secondFee = 0;

      if(feesParams.fee.calculWay === "Fixe"){
        firstFee =  parseFloat(feesParams.fee.processFee);
      }else if(feesParams.calculWay === "Pourcentage"){
        firstFee =  parseFloat(feesParams.fee.processFee) * parseFloat(givenAmount) / 100;         
      }

      if(getFee){
        if(getFee.calculWay === "Fixe"){
          secondFee =  parseFloat(getFee?.bankFee);
        }else if(getFee.calculWay === "Pourcentage"){
          secondFee =  parseFloat(getFee?.bankFee) * parseFloat(givenAmount) / 100;         
        }
      }

       
      totalFees = firstFee  +  secondFee;
    }

    let totalSent = parseFloat(totalFees) + parseFloat(givenAmount);


    await this.setState({
      formPayload : {
        ...this.state.formPayload,
        fee : {value : `${totalFees}`},
        receivedAmount : {value : `${totalReceived}`},
        total : {value : `${totalSent}`},
      }
    });

  }

  async transactionValidation(payload){
    let response = null;
    const service =  this.state.service || this.loadExternalCommunicationService();
    let message = "";
    response = await service?.valid(payload); 
    
    if(response?.success){
      this.initDataLoading();
      this.setState({closeDialog : !this.state.closeDialog});
      this.loadFormMessage("success",response?.message || "Succès "+message);
    }else{
      this.setState({closeDialog : true});
      this.loadFormMessage("error",response?.message || "Echec "+message);
    }    
  }
}
