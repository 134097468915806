import { Autocomplete, TextField } from "@mui/material";
import { Fragment, useState } from "react";

export default function PersonalInfo(props){
    const [personalInfo,setPersonalInfo] = useState(props.oldValue || {});
    const globalInfo = props.globalInfo || {};
    const [beneficiaries,setBeneficiaries] = useState(props.beneficiaries || []);
    const [filterBeneficiaries,setFliterBeneficiaries] = useState(props.beneficiaries || []);
    
    const changeText = (e, value = null, name = null) => {
        e?.preventDefault();
        setPersonalInfo({
            ...personalInfo,
            [name || e.target.name] : value || e.target.value  
        });
    }

    const setInfo = (info) => {
        props.setFormInfo({...globalInfo,personalInfo : personalInfo});
    }

    const changeBeneficairiesList = (value) => {
      let listOfBeneficiaries = beneficiaries; 
       
      if(value){
        listOfBeneficiaries = listOfBeneficiaries.filter(
            benef => benef?.originalObject?.client?.id === value?.value
        );
       }
       
       setFliterBeneficiaries(listOfBeneficiaries.length > 0 ? listOfBeneficiaries : [])
       setBeneficiaries(props.beneficiaries)
       
    }

    return <Fragment>
            <div className='my-2'>
                <h5>INFORMATION SUR CLIENT & BENEFICIAIRE</h5> 
            </div>
            <div className="amount row">
                    <div className="col-6  my-3">
                        <Autocomplete
                            options={props.clients || []}
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField error={props?.errors?.find(error => error.name === "sender")?.error}
                            helperText={props?.errors?.find(error => error.name === "sender")?.error || null} fullWidth {...params} label="Envoyeur" />}
                            name = "sender"
                            onChange={(e,value) => 
                                {
                                    changeText(e,value,"sender");
                                    changeBeneficairiesList(value);
                                }
                            }
                            value={personalInfo.sender}
                            error={props?.errors?.find(error => error.name === "sender")?.error}
                            helperText={props?.errors?.find(error => error.name === "sender")?.error || null}
                            onBlur={(e) =>  setInfo(personalInfo)}
                        />
                    </div>
                    <div className="col-6  my-3">
                        <Autocomplete
                            options={filterBeneficiaries || []}
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField error={props?.errors?.find(error => error.name === "receipter")?.error}
                            helperText={props?.errors?.find(error => error.name === "receipter")?.error || null} fullWidth {...params} label="Receveur" />}
                            name = "receipter"
                            onChange={(e,value) => {changeText(e,value,"receipter")}}
                            value={personalInfo.receipter}
                            error={props?.errors?.find(error => error.name === "receipter")?.error}
                            helperText={props?.errors?.find(error => error.name === "receipter")?.error || null}
                            onBlur={(e) =>  setInfo(personalInfo)}
                        />
                    </div>
              
            </div>
        </Fragment>

}