import DefaultController from "./default.controller";
import ParamsController from "./params.controller";

export default class BeneficiariesController extends DefaultController {
    constructor () {
      super ({
        path: 'beneficiaries',
      });
    }
  
    paramController = new ParamsController ();
  
  
    rules = [
      {title: 'name', rules: ['required']},
      {title: 'tel', rules: ['required','unique:clients:tel']},
      {title: 'email', rules: ['unique:clients:email']},
      {title: 'accountNumber', rules: ['required']},
    ];
  
    async otherTreatCredentials (credentials){
      const newSaveCred = {
        ...credentials,
        state : 1,
      };
      
      return newSaveCred;
    }
  
    clientBeneficiaries = async(clientId)   =>  {
      const clientssQuery = await this.readAll ();
      return {success: true, data: clientssQuery.data.filter (beneficiary => beneficiary.client?.id === clientId),message : 'succes recuperation benef'};
    }
  }