import "./style.css";
import { useReactToPrint } from "react-to-print";
import { useRef, useState } from "react";
import { Button, Card, CardContent } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import profile from "../../../../../assets/images/profile.jpg";
import FrameTitleLinked from "../../../../../vendor/builders/Frame/_layouts/title/title-linked";
import ClientTransactions from "../transactions";
import ClientAffiliates from "../affiliates";
import ClientPrimes from "../primes";
import { Fragment } from "react";

const SingleClientRender = (props) => {
  const clientInformation = props.data.rows;

  const componentRef = useRef();
  const onPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [currentComponent, setCurrentComponent] = useState("transactions");

  const [currentSubParams, setCurrentSubParams] = useState({
    clientId: clientInformation?.id || null,
  });

  const [currentActiveBtnName, setCurrentActiveBtnName] =
    useState("transactions");
  const [currentComponentName, setCurrentComponentName] =
    useState("transactions");
  const [currentComponentTitle, setCurrentComponentTitle] =
    useState("Transction(s)");

  const handleChangeSubComponent = (e, value) => {
    if (value === "transactions") {
      setCurrentComponent("transactions");
      setCurrentSubParams({
        clientId: clientInformation.id,
      });
      setCurrentActiveBtnName("transactions");
      setCurrentComponentName("transactions");
      setCurrentComponentTitle("Transaction(s)");
    } else if (value === "affiliates") {
      setCurrentComponent("affiliates");
      setCurrentSubParams({
        clientId: clientInformation.id,
      });
      setCurrentActiveBtnName("affiliates");
      setCurrentComponentName("affiliates");
      setCurrentComponentTitle("Affilié(s)");
    } else if (value === "primes") {
      setCurrentComponent("primes");
      setCurrentSubParams({
        clientId: clientInformation.id,
      });
      setCurrentActiveBtnName("primes");
      setCurrentComponentName("primes");
      setCurrentComponentTitle("Bonus");
    }
  };

  const CurrentComponent = (name) => {
    switch (name) {
      case "affiliates":
        return (
          <ClientAffiliates
            clientId={clientInformation?.id}
            params={currentSubParams}
          />
        );
      case "transactions":
        return (
          <ClientTransactions
            clientId={clientInformation?.id}
            params={currentSubParams}
          />
        );
      case "primes":
        return (
          <ClientPrimes
            clientId={clientInformation?.id}
            params={currentSubParams}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="client-card-information">
      <div className="d-flex">
        <Button
          variant="outlined"
          sx={{ color: "white", bgcolor: "green", mr: 1 }}
          startIcon={<FontAwesomeIcon icon="print" />}
          onClick={onPrint}
        >
          Imprimer
        </Button>
      </div>
      <div ref={componentRef} className="mt-2 p-2">
        <div className="client-content">
          <div className="p-2 text-center text-white client-title">
            <h4>FICHE CLIENT #{clientInformation?.id}</h4>
          </div>
          <div className="card-body p-3 m-1">
            <div className="d-flex align-items-center">
              <div className="image bg-light p-2 mr-3">
                <img src={profile} alt="logo" className="rounded" width="100" />
              </div>
              <div className="p-2 w-100">
                <h5 className="mb-0 mt-0">{clientInformation?.name}</h5>
                <small>Client </small>
                <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                  <div className="d-flex flex-column">
                    <span className="articles">N° de téléphone</span>
                    <span className="number1">{clientInformation?.tel}</span>
                  </div>
                </div>
              </div>
            </div>
            {
                clientInformation?.id ? 
                <Fragment>
                      <FrameTitleLinked
                        activeBtnName={currentActiveBtnName}
                        name={currentComponentName}
                        title={currentComponentTitle}
                        handleActionClick={handleChangeSubComponent}
                        actions={[
                            {
                            name: "transactions",
                            title: "Transaction(s)",
                            },
                            /* {
                                                name : "primes",
                                                title: "Bonus",
                                            },*/
                            {
                            name: "affiliates",
                            title: "Affilié(s)",
                            },
                        ]}
                        />
                        <Card sx={{ width: "100%" }}>
                            <CardContent>{CurrentComponent(currentComponent)}</CardContent>
                        </Card>
                </Fragment> : null
            }
          
          </div>
        </div>
        <div className="d-flex justify-content-center image-text border-top">
          <div className="text logo-text">
            <span className="name">FICHE CLIENT WELL TRANSFER</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleClientRender;
