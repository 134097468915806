import UsersService
  from '../../../external-communication/services/users.service';
import {connect} from 'react-redux';
import { Navigate } from 'react-router-dom';
import BaseFrontComponent from '../base-front-component';
import { withRouter } from '../../../bootstrap/Helpers/with-router';

class RegisterComponent  extends BaseFrontComponent{
  name = "Register";
  state = {
    ...this.state,
  };


  async submitForm (e, formValue = null) {
    e.preventDefault ();
    const service = new UsersService ();

    const values = formValue || this.state.formPayload;

    const serviceResponse = await service.register ({...values,
      roles:{value : {name : 'client'}},
      sponsoringCode :{value : this.props.router.params.sponsoringCode || null}});
    if(serviceResponse.success){
      this.loadFormMessage('success',"Connecté avec succès redirection");
    
      await this.setState ({
        redirect: true,
      });
      
    }else{
      this.loadFormMessage('error',serviceResponse.message,serviceResponse?.errors)
    }
   
  }

  render () {
    if (this.state.redirect) {
       return <Navigate to={'/login'} />
    }
    const RenderScreen = this.loadScreen ();
    return <RenderScreen data={this.state} />;
  }

}

const mapStateToProps = state => state;


export default withRouter(connect (mapStateToProps) (RegisterComponent));
