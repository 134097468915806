import dataFormatter from '../responses/row-formatter.response';
import DefaultController from './default.controller';

export default class FeesController extends DefaultController {
  
  rules = [
    {title: 'name', rules: ['required', 'unique:fees:name']},
    {title: 'currency', rules: ['required']},
  ];

  constructor () {
    super ({
      path: 'fees',
    });
  }

  async otherTreatCredentials (credentials){
    return {
      ...credentials,
      state : 1,
      feesList : credentials.feesList || []
    };
  }

  async otherUpdateTreatCredentials (credentials){
    return {
      ...credentials,
      feesList : credentials.feesList || []
    };
  }

  async activeFee(id){

    const response = await this.readFirebase (
      `${('fees' || this.path) + '/' + id}`
    );

    const data = {
      ...dataFormatter.SingleFormatterResponse (response.data),
      state : 1
    };


    return this.update(id,data)
  }


  async disableFee(id){
    const response = await this.readFirebase (
      `${('fees' || this.path) + '/' + id}`
    );

    const data = {
      ...dataFormatter.SingleFormatterResponse (response.data),
      state : 0
    };
    return this.update(id,data)
  }
}
