import avatarImg from './../../../../../../../assets/images/avatar.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

export default function SideFooter (props) {
  return (
    <AppBar
      sx={{
        color: props.open ? '#000039' : 'rgb(242,242,245)',
        background: props.open ? 'rgb(242,242,245)' : '#000039',
        position: 'absolute',
        bottom: 0,
      }}
      position="static"
    >
    <Toolbar>
      <div className='d-flex justify-content-around bg-gray' 
        style={{
          color: props.open ? '#000039' : 'rgb(242,242,245)',
          background: props.open ? 'rgb(242,242,245)' : '#000039',
        }}
      >
          <img className={`img profile-img ${props.open ? '' : 'd-none'}`} src={props.user.image ?? avatarImg} alt=""/>
          <Typography
            sx={{
              display: props.open ? '' : 'none',
              mr: props.open ? 1 : 0,
              flexDirection: 'row',
              fontSize: '16px',
            }}
            variant="h6"
            component="div"
          >
            

          <div className="d-flex flex-column">
            <span className="user-name">
              {props.user.firstName + ' ' + props.user.lastName}
            </span>
            <small className="text-muted fw-bold user-role">
              {props.user.role}
            </small>
          </div>
          </Typography>
          <IconButton
            size="small"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{
              minWidth: 0,
              mr: props.open ? 0 : 'auto',
              justifyContent: props.open ? 'start' : 'center',
            }}
            onClick={(e) => {
              props.openDialog(e,"logout")
            }
            }
          >
            <FontAwesomeIcon icon="sign-out-alt" />
          </IconButton>
      </div>
    </Toolbar>
    </AppBar>
  );
}
